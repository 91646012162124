import React, { useState } from "react";
import { RiArrowDropRightLine } from "@react-icons/all-files/ri/RiArrowDropRightLine";
import "./LatestNews.scss";
import Cards from "../Cards/Cards";
import LatestNewPhones from "../LatestNewPhones/LatestNewPhones";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  caseSuccess,
  caseError,
  caseErrorCheck,
  caseSuccessCheck,
} from "../Validations/SubscriptionForm";

const LatestNews = () => {
  const [numberOfItems, setNumberOfItems] = useState(10);
  const [email, setEmail] = useState("");
  const [check, setCheck] = useState(false);
  const [response, setResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [load, setLoad] = useState("");
  const [checkResponse, setCheckResponse] = useState("");
  const [accessToken, setAccessToken] = useState('SW8DnHlcXfL0qZ3p3A6JVFCGuZHgbQBY')
  const [refreshToken, setRefreshToken] = useState('6A9C0ye3apjd8TqtIdXNiKaPCqtEuLu9')

  const handleLoader = (count: string) => {
    setLoad(count);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };
  const checkInput = (e) => {
    setCheck(e.target.checked);
  };

  function resLoad(setRes, setLoad) {
    setResponse(setRes);
    setIsLoading(setLoad);
  }

  const refreshTokenUrl = `https://auth.aweber.com/oauth2/token?grant_type=refresh_token&refresh_token=${refreshToken}`;

  const refreshAccessToken = async () => {
    try {
      const res = await fetch(refreshTokenUrl, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Basic ' + btoa('FawoOSmefXDLBCV6xcxybCfKb2Fiu2wC:TXyuJq1DjoM1sMkkgpEtv84h71twxLXf'),
        },
        method: "POST",
      })
      const data = await res.json()
      setAccessToken(data.access_token);

      //after refresh token adding subscriber
      // ----------------------------------------------------------------------------------------------------

      await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "User-Agent": "AWeber-Node-code-sample/1.0",
          "Authorization": `Bearer ${data.access_token}`
        },
        method: "POST",
        body: JSON.stringify({
          email: email
        })
      })
        .then(data => {
          if (data.status == 201) {
            caseSuccess("responseMsg");
            resLoad("You have successfully subscribed.", false);
            setEmail('');
            setCheck(false)
          }
          else {
            //in case the email already used or unacceptable email
            caseError("responseMsg");
            resLoad("Use Another Email.", false);
          }
        })
        .catch(err => {
          caseError("responseMsg");
          resLoad("Invalid Email.", false);
        })
      // ----------------------------------------------------------------------------------------------------
    } catch (e) {
      console.log(e)
    }
  }

  const Useremail = async () => {
    setIsLoading(true);
    if (email != "") {
      await fetch("https://api.aweber.com/1.0/accounts/1958895/lists/6347465/subscribers", {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "User-Agent": "AWeber-Node-code-sample/1.0",
          "Authorization": `Bearer ${accessToken}`
        },
        method: "POST",
        body: JSON.stringify({
          email: email
        })
      })
        .then(data => {
          if (data.status == 201) {
            caseSuccess("responseMsg");
            resLoad("You have successfully subscribed.", false);
            setEmail('');
            setCheck(false)
          }
          else if (data.status == 401) {
            refreshAccessToken();
            //code to refresh access token 
          }
          else {
            //in case the email already used or unacceptable email
            caseError("responseMsg");
            resLoad("Use Another Email.", false);
          }
        })
        .catch(err => {
          caseError("responseMsg");
          resLoad("Invalid Email.", false);
        })
    } else {
      caseError("responseMsg");
      resLoad("Please enter all details", false);
    }
  };

  return (
    <div className="latest-news pt-3 px-2">
      <div className="container">
        <div className="row">
          <div className="latest-news-title pb-4 pb-md-2">
            <span>LATEST NEWS</span>
          </div>
          <div className="col-lg-8 pt-lg-4">
            <div className="gy-3">
              <Cards
                content="LATESTNEWS"
                numberOfItems={numberOfItems}
                loaders={handleLoader}
              />

              <div className="col-lg-12">
                <div className="mb-5">
                  {parseInt(load) >= numberOfItems && (
                    <button
                      className="cs-load-more"
                      onClick={() => setNumberOfItems((state) => state + 10)}
                    >
                      Load More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="latest-news-form">
              <div className="grey-background mb-5">
                <div className="inner-container">
                  <h5 className="">Sign Up To Updates</h5>
                  {/* <strong>
                    Get notified of the best deals on our WordPress themes.
                  </strong> */}
                  <input
                    className="mt-4 mb-3 px-2"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleChange}
                  />
                  <p className="responseMsg">{response}</p>
                  {isLoading ? <LoadingSpinner /> : null}

                  <button
                    className="mb-3"
                    type="button"
                    onClick={() => {
                      if (!check) {
                        caseErrorCheck("checkRes");

                        setCheckResponse(
                          "Please confirm that you agree with our policies."
                        );
                      } else {
                        Useremail();
                      }
                    }}
                  >
                    SUBSCRIBE
                    <RiArrowDropRightLine className="icon" />
                  </button>
                  <div className="check-box">
                    <input
                      type="checkbox"
                      checked={check}
                      onChange={(e) => {
                        checkInput(e);
                        if (check) {
                          caseErrorCheck("checkRes");
                          setCheckResponse(
                            "Please confirm that you agree with our policies."
                          );
                        } else {
                          caseSuccessCheck("checkRes");
                          setCheckResponse("");
                        }
                      }}
                    />
                    <p>
                      By checking this box, you confirm that you have read and
                      are agreeing to our terms of use regarding the storage of
                      the data submitted through this form.
                    </p>
                    <p className="checkRes">{checkResponse}</p>
                  </div>
                </div>
              </div>
            </div>
            <LatestNewPhones />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(LatestNews);