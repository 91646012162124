import React, { useEffect } from "react";
import "./Cards.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
// import ReviewBelowContent from "../ReviewBelowContent/ReviewBelowContent";
import NewsBelowContent from "../NewsBelowContent/NewsBelowContent";

interface Cards {
  variant?: "LARGE" | "SMALL";
  content?: "NEWS" | "REVIEWS" | "LATESTNEWS" | "GADGETS";
  numberOfItems?: number;
  loaders: (count: string) => void;
}
const Cards: React.FC<Cards> = ({
  variant,
  numberOfItems,
  loaders,
}) => {
  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { fields: date, order: DESC }) {
        totalCount
        nodes {
          slug
          date
          Title
          id
          description {
            data {
              description
            }
          }
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          ReadTime
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, height: 400)
              }
            }
          }
        }
      }
    }
  `);
  useEffect(() => {
    loaders(data.allStrapiPost.totalCount);
  }, [])
  return (
    <div className={variant === "LARGE" ? "reviews-content row" : "reviews-content row"}>
      {data.allStrapiPost.nodes.slice(0, numberOfItems ?? 3).map((e, i) => {
        return (
          <div className="col-lg-6 col-md-6" key={e.id}>
            <Link to={`/${e.slug}/`}>
              <div className="position-relative">
                <GatsbyImage
                  image={e.image.localFile?.childImageSharp?.gatsbyImageData}
                  style={{
                    height: "100%",
                    width: "100%",
                    minHeight: "280px",
                  }}
                  imgStyle={{ height: "100%" }}
                  alt="reviews-content"
                  className="img-fluid"
                  objectFit="cover"
                  loading="eager"
                />
                <div className="read-more">
                  <div className="text">READ MORE</div>
                </div>
              </div>
            </Link>
            <NewsBelowContent
              description={e.Title}
              descriptionLink={e.slug}
              category={e.blog_categories}
              adminlink={e.admin_users[0].username.toLowerCase().replace(" ", "-")}
              admin={e.admin_users[0].username}
              date={new Date(e.date)
                .toDateString()
                .split(" ")
                .slice(1, 4)
                .toString()
                .replace(",", " ")}
            />
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Cards);