import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, Link, useStaticQuery } from "gatsby";
import { BsArrowRight } from "@react-icons/all-files/bs/BsArrowRight";

import "./LatestNewPhones.scss";

const LatestNewPhones = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { order: DESC, fields: date },limit:4) {
        nodes {
          slug
          date
          Title
          id
          admin_users {
            username
          }
          blog_categories {
            category
            slug
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 540)
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="newphone-content pt-5 ms-0 ms-md-3 ps-0 ps-md-4">
        <div className="new-phone-title">
          <span>NEW PHONES</span>
        </div>
        {data.allStrapiPost.nodes.map((e, i) => {
          return (
            <div className=" border-down d-flex" key={e.id}>
              <div className="">
                <div className="new-phone py-4">
                  <div className="images-phones ">
                    <GatsbyImage
                      image={
                        e.image.localFile?.childImageSharp?.gatsbyImageData
                      }
                      style={{
                        height: "100%",
                        minHeight: "100px",
                      }}
                      imgStyle={{ height: "75%" }}
                      alt="reviews-content"
                      className="img-fluid"
                      loading="eager"
                      objectFit="cover"
                    />
                  </div>
                  <div className="new-phone-overlay">
                    <Link to={`/${e.slug}/`}>
                      <div className="position-relative">
                        <h3 className="pt-4">{i + 1}</h3>
                        <BsArrowRight />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div>
                <div className="right-content py-4 ps-3">
                  <Link to={`/${e.slug}/`}
                    dangerouslySetInnerHTML={{ __html: e.Title }}></Link>
                  <div className="d-flex align-items-baseline pb-0 pb-md-4 pt-md-2 pt-0">
                    <ul className="pt-3 news-list text-uppercase pe-1">
                      {e.blog_categories &&
                        e.blog_categories.map((e) => {
                          return (
                            <li key={e.category}>
                              <Link className='tag' to={`/category/${e.category.toLowerCase()}/`}>
                                {e.category}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                    <div className="admin-name d-flex align-items-baseline  pt-1">
                      {/* <Link to={`/${e.admin_users[0].username.replace(" ", "-")}/`}>
                        <small>
                          by
                          <span className="ps-1 fw-bold" >
                            {e.admin_users[0].username}
                          </span>
                        </small>
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default React.memo(LatestNewPhones);
